<!-- TODO: isolate program > composables/smaller files before moving to composition API -->
<!-- Instead of signedInEvents.sort(), we should sort by date -->

<template>
  <h3>VIZBI <span>Live</span></h3>
  <TimeZone />
  <br />
  <div v-if="!showEvents()" id="signin">
    This page will provide access the Masterclass and VIZBI videos after they have streamed.
  </div>
  <div v-else-if="!signedInEvents.length || signedInEvents?.length === 0" class="clearBoth">
    <SignIn :to="events" @signedIn="signedInTo" />
  </div>
  <div v-else class="clearBoth">
    <p class="intro">
      This page gives access to the live video streams for the VIZBI Masterclass and Workshop. We
      recommend you use the
      <a href="https://support.zoom.us/hc/en-us/articles/201362233">latest Zoom desktop client</a>.
    </p>
    <!-- <p class="intro">
      If you need help, you can contact the VIZBI support team via
      <a href="https://app.purechat.com/w/byqeil">this chat link</a>
    </p> -->
    <LiveEvent
      v-for="eventName in signedInEvents.sort()"
      :key="eventName"
      :event-name="eventName"
      :time-zone="timeZone" />
  </div>
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(`Live: ${msg}`); // eslint-disable-line
import {ref, computed, onMounted} from "vue";
import TimeZone from "@/components/TimeZone";
import LiveEvent from "@/components/LiveEvent";
import SignIn from "@/components/SignIn";
import history from "@/assets/history.json";
import showEvents from "@/composables/useShowEvents";
const year = history.latestYear;
const [timeZone, signedInEvents] = [ref(""), ref([])];
//let showLinkAt = 1500000; // by default, show link 15 mins prior

const events = computed(() => [`Masterclass ${year}`, `VIZBI ${year}`]);

function signedInTo(userEvents) {
  log(`signedInTo = ${JSON.stringify(userEvents)}`);
  signedInEvents.value = userEvents;
}

onMounted(() => log(`mounted`));
</script>

<style scoped>
h3 {
  display: inline;
  float: left;
}
.clearBoth {
  clear: both; /* needed to clear two floats above */
  margin-top: 5px;
}
p.intro {
  font-weight: 300;
  font-style: italic;
}
div.description {
  text-align: left;
  text-decoration: none;
  font-size: 1.1rem;
  color: black;
}
h3 span {
  font-weight: 300;
}
</style>

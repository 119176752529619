<!-- only show if the local and conference time zones differ -->

<template>
  <div v-if="timeZoneDiffers" id="timezone">
    Time zone:
    <Dropdown
      :placeholder="timeZone"
      :options="[timeZoneConference, timeZoneLocal].sort()"
      :selected="timeZone"
      :tag="false"
      @update="switchTimeZone" />
  </div>
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(`TimeZone: ${msg}`); // eslint-disable-line
import {computed, inject, onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {useStorage} from "@vueuse/core"; // Simplify state persistence
import Dropdown from "@/components/Dropdown";
import vizbiHistory from "@/assets/history.json";
const year = vizbiHistory.latestYear;
const timeZoneConference = vizbiHistory.years[year];
const timeZoneLocal = Intl.DateTimeFormat().resolvedOptions().timeZone;
const {DateTime} = require("luxon");
const route = useRoute();

const timeZone = useStorage("timeZone"); // Persist state in localStorage

const timeZoneDiffers = computed(() => {
  // tests if the local time zone is the same as the conference time zone
  if (!timeZoneConference) {
    // early return if the original program hasn't been mounted yet
    return false;
  }
  let localTime = DateTime.local(); // get current time in local timeZone
  log(`timeZoneDiffers: localTime (${localTime})`);
  let timeInConferenceZone = localTime.setZone(timeZoneConference);
  // rezone to conference without changing timestamp;
  log(`timeZoneDiffers: timeInConferenceZone (${timeInConferenceZone})`);
  return timeInConferenceZone.toString() !== localTime.toString();
});
function switchTimeZone(newTimeZone) {
  timeZone.value = newTimeZone;
  timeZone.value = newTimeZone;
  setTimeZoneInUrl(newTimeZone);
}
function setDayAndTimeZoneFromURL() {
  let parameters = route.hash.replace(/^#/, "").split("&");
  if (parameters[0] && parameters[0].match(/-/)) {
    // if the next remaining parameter is a time zone, ...
    log(`URL includes time zone '${parameters[0]}'`);
    /// overwrite this with the local time zone
    timeZone.value = timeZoneLocal;
    return;
  } else if (timeZone.value) {
    // if no time zone is set in the URL, ...
    log(`Using time zone in store: ${timeZone.value}`);
  } else {
    // initially, defaults to conference time zone (not local time zone).
    // user can then change it
    timeZone.value = timeZoneConference;
  }
}
function setTimeZoneInUrl(timeZone) {
  log(`setTimeZoneInUrl(${timeZone})`);
  if (!timeZone) return;
  if (timeZone === timeZoneConference) {
    history.pushState({}, null, route.path);
  } else {
    const hash = timeZone.replace("/", "-");
    history.pushState({}, null, `${route.path}#${hash}`);
  }
}
onMounted(() => setDayAndTimeZoneFromURL());
</script>

<style scoped>
#timezone {
  float: right;
  margin-top: 12px; /* same as App.vue/h3 */
  margin-bottom: 8px; /* same as App.vue/h3 */
  font-size: 14px;
  line-height: 33px;
  clear: right; /* https://stackoverflow.com/q/3415635 */
}
</style>

<!-- Copyright: Seán I. O'Donoghue -->
<!-- <Zoom v-if="item.stream && live" :id="item.stream" /> -->
<!-- <p v-else-if="item.description" class="description">
  {{ item.description }}
</p> -->

<template>
  <section>
    <!-- <Zoom v-if="live && item.stream" :id="item.stream" class="leftColumn" /> -->
    <div v-if="live && item.stream" class="leftColumn">
      <Zoom :id="item.stream" immediate />
    </div>
    <div v-else class="leftColumn">
      <!-- <p>{{ JSON.stringify(item, null, 2) }}</p> -->
      <router-link
        v-if="item.name && item.bio"
        class="item"
        :to="`/2025/People#${item.name.replaceAll(' ', '_')}`">
        <Suspense>
          <img v-if="highlightImage" :src="highlightImage" />
          <!-- <img v-if="highlightImage" src="/2025/Hero.jpg" /> -->
          <!-- <img :src="useHighlightImage(item.name)" /> -->
          <!-- <img :src="useHighlightImage(item.name)" /> -->
          <!-- <PersonHeadshot :key="`${item.name}-headshot`" :person="item.name" /> -->
          <!-- <PersonHeadshot
            :person="item.name"
            :folder="`years/${year}/People/img/large`"
            :extension="'jpg'" /> -->
          <!-- @click="goToPerson(item.name)" -->
        </Suspense>
      </router-link>
      <img v-else src="/2025/Hero.jpg" />
    </div>
    <div class="rightColumn">
      <!-- not sure if timeStamp is right format -->
      <time :datetime="item.startTimeStamp">
        {{ `${item.date}, ${item.start} - ${item.end}` }}
      </time>
      <h4 v-html="item.title" />
      <h5>{{ item.name }}</h5>
      <div v-if="false">
        <p>title = {{ item.title }}</p>
        <p>waiting-room-open = {{ waitingRoomOpen }}</p>
        <p>live = {{ live }}</p>
        <p>ended = {{ ended }}</p>
        <p>name = {{ item.name }}</p>
      </div>
      <div class="fieldAndCounter">
        <fieldset>
          <legend v-if="live">Live now</legend>
          <legend v-else-if="ended">Ended</legend>
          <legend v-else>Starts in</legend>
        </fieldset>
      </div>
      <div class="countdown">
        <Countdown
          v-if="showCounter"
          :until="item.startTimeStamp"
          :highlight="count === 0"
          @hideCounter="showCounter = false" />
      </div>
      <!-- <Zoom v-if="live" :id="item.stream" /> -->
      <div v-if="live || ended || waitingRoomOpen" class="fieldAndCounter">
        <span>
          <VideoLink
            :waiting-room-open="waitingRoomOpen"
            :live="live"
            :ended="ended"
            :person="item.name"
            :event-title="item.title"
            :stream="item.stream"
            :video="item.video" />
        </span>
      </div>
    </div>
  </section>
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(`EventItem: ${msg}`); // eslint-disable-line
const props = defineProps({
  item: {default: () => ({})},
  count: {default: null},
  showLinkAt: {default: 15}
});
import {ref, onMounted, onBeforeUnmount, watch} from "vue";
import {DateTime, Interval} from "luxon";
import Countdown from "@/components/Countdown.vue";
import Zoom from "@/components/Zoom";
import VideoLink from "@/components/VideoLink.vue";
import history from "@/assets/history.json";
const year = history.latestYear;
const emit = defineEmits(["ended"]);
const ended = ref(false);
const live = ref(false);
const waitingRoomOpen = ref(false);
const showCounter = ref(true);
const now = ref(DateTime.local());
const tick = ref(null);
const highlightImage = ref(null);

async function useHighlightImage(person) {
  const underscored = person.replaceAll(" ", "_");
  log(`person = ${person} → ${underscored}`);
  log(`year = ${year}`);
  try {
    highlightImage.value = require(`@/assets/years/${year}/People/img/large/${underscored}.jpg`);
    log(`found image at ${highlightImage.value}`);
  } catch (error) {
    log(`No large highlight image found for ${person}`);
  }
}

watch(now, () => {
  if (ended.value) return;
  if (now.value >= props.item.endTimeStamp) {
    if (!ended.value) log(`event has ended`); // print once only
    ended.value = true;
    live.value = false;
    emit("ended", props.item.startTimeStamp);
    return;
  }
  if (now.value >= props.item.startTimeStamp) {
    if (!live.value) log(`event has started`); // print once only
    if (!live.value) log(`startTimeStamp = ${props.item.startTimeStamp}`); // print once only
    if (!live.value) log(`endTimeStamp = ${props.item.endTimeStamp}`); // print once only
    live.value = true;
    waitingRoomOpen.value = false;
    return;
  }
  // log(`event is now within the waiting room`);
  const interval = Interval.fromDateTimes(now.value, props.item.startTimeStamp);
  if (interval.length("minutes") <= props.showLinkAt) {
    waitingRoomOpen.value = true;
  }
});

function startTimer() {
  return setInterval(() => {
    now.value = DateTime.local();
  }, 100);
}

onMounted(async () => {
  tick.value = startTimer();
  await useHighlightImage(props?.item?.name);
  document.addEventListener("visibilitychange", () => {
    if (document.hidden) {
      console.log("tab now inactive - temporarily clear timer");
      clearInterval(tick.value);
    } else {
      console.log("tab is active again, so restarting timer");
      tick.value = startTimer();
    }
  });
});

onBeforeUnmount(() => {
  clearInterval(tick.value);
});
</script>

<style scoped>
section {
  display: flex;
  flex-direction: column-reverse; /* sets main axis as vertical */
  gap: 20px; /* vertical spacing between children */
  padding: 20px; /* separates border from children */
  align-items: center; /* aligns children + text within children */
  border-width: 1px;
  border-radius: 12px;
  border-color: #d9d9d9;
  background-color: #f1f1f1;
}
.wide section,
.unlimited section {
  /* on wide screens, switch to rows */
  flex-direction: row;
}
section > * {
  /* selects direct children of section > left & right columns */
  /* https://stackoverflow.com/q/43739470 */
  flex: 1; /* give each child approximately equal height */
  min-width: 0; /* prevents from overflowing */
}
div.leftColumn {
  align-self: stretch; /* fill horizontal space */
  min-height: 100%;
  /* background-color: black; */
}
div.rightColumn {
  display: flex;
  flex-direction: column; /* sets main axis as vertical */
  gap: 12px; /* vertical spacing between children */
  align-items: stretch; /* fill horizontal space */
}
div.rightColumn > * {
  /* selects all direct children > parts of right column */
  flex-grow: 1;
  flex-basis: 0;
  margin: 0px;
  padding: 0px;
  min-width: 0; /* prevents from overflowing */
}
time {
  display: block;
  color: #929292;
  text-align: center;
}
h4 {
  /* title */
  text-align: center;
  font-weight: 700;
}
h5 {
  /* name */
  color: gray; /* a bit darker gray; */
  text-align: center;
  font-weight: 600;
}
p {
  /* description */
  text-align: left;
  text-decoration: none;
  font-size: 1.1rem;
  color: black;
  align-items: stretch; /* fill horizontal space */
}
div.fieldAndCounter {
  min-width: 0;
  text-align: center;
  padding: 0px;
  margin: 0px;
}
fieldset {
  border: 2px dashed transparent;
  border-top-color: #929292;
  color: #353535;
  padding: 0px;
  margin: 0 0 1rem 0;
  min-width: 0; /* prevents from overflowing */
}
legend {
  margin: auto; /* to center */
  padding: 3px 12px;
  background-color: #d1d1d1;
  color: rgb(65, 65, 65);
}
div.countdown {
  min-width: 0;
  max-width: 100%;
  text-align: center;
}
/* https://stackoverflow.com/q */
img {
  /* max-width: 350px;
  min-width: 200px; */
  -webkit-filter: drop-shadow(2px 2px 5px #9c9c9c);
  filter: drop-shadow(2px 2px 5px #9c9c9c);
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
}
</style>

<!-- Copyright: Seán I. O'Donoghue -->
<!-- The meta tag should probably be in public/index.html -->
<!-- But that causes errors -->
<!-- <div v-show="host.match(/^localhost|^192/)" class="debugMessage">
    <p>to = {{ to }}</p>
    <p>step = {{ step }}</p>
  </div> -->

<template>
  <meta
    http-equiv="Content-Security-Policy"
    content="default-src *; style-src 'self' 'unsafe-inline'; script-src 'self' 'unsafe-inline' 'unsafe-eval'" />
  <transition v-show="to" mode="out-in" appear>
    <section v-if="step === 1">
      <h5>
        To view this
        <span v-if="host.match(/video/i)">video,</span>
        <span v-else>page,</span>
        register for
        <span v-if="typeof to === 'string'">{{ to }}</span>
        <span v-else>{{ formatStringArray(to) }}</span>
      </h5>
      <p v-show="message" class="message">{{ message }}</p>
      <div>
        <router-link to="/Registration">
          <ButtonBespoke>Register</ButtonBespoke>
        </router-link>
        <ButtonBespoke @click.once="step = 2">I already registered</ButtonBespoke>
        <!--<ButtonBespoke v-if="address">Use a different address</ButtonBespoke> -->
      </div>
    </section>
    <section v-else-if="step === 2">
      <h5>Please enter the email you used for registration:</h5>
      <p v-show="message" class="message">{{ message }}</p>
      <keep-alive>
        <input
          ref="emailInput"
          v-model="address"
          type="email"
          placeholder="Type your email address"
          @keypress.enter="verify_email(address)" />
      </keep-alive>
      <div>
        <span @click.once="[step, message] = [1, '']">
          <ButtonBespoke>Go back</ButtonBespoke>
        </span>
        <ButtonBespoke
          :key="clickCount"
          :disabled="!email_is_valid(address)"
          @click.once="verify_email(to)">
          Sign in
        </ButtonBespoke>
      </div>
    </section>
    <section v-else-if="step === 3">
      <h5>Check your email for the code to authenticate this browser:</h5>
      <p>In some cases, you may need to check your junk folder.</p>
      <input
        ref="tokenInput"
        v-model="token"
        type="text"
        autocomplete="off"
        placeholder="Paste authorisation code here" />
      <div>
        <span @click.once="[step, message] = [2, '']">
          <ButtonBespoke>Go back</ButtonBespoke>
        </span>
        <ButtonBespoke :disabled="isToken" @click.once="authenticate(token)"
          >Authenticate</ButtonBespoke
        >
      </div>
    </section>
    <section v-else-if="step === 4">
      <h5>This authentication code {{ tokenError }}.</h5>
      <p>Press OK to continue.</p>
      <ButtonBespoke @click.once="isSignedIn()">OK</ButtonBespoke>
    </section>
  </transition>
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(`SignIn: ${msg}`); // eslint-disable-line
const props = defineProps(["to"]);
import {ref, watch, computed, onMounted} from "vue";
import {useRoute, useRouter} from "vue-router";
import ButtonBespoke from "@/components/ButtonBespoke";
import stringify from "json-stringify-safe";
const emit = defineEmits(["signedIn"]);
const address = ref(localStorage.email || "");
const clickCount = ref(1);
const host = ref(window.location.host);
const token = ref("");
const tokenError = ref("");
const message = ref("");
const step = ref(1);
const emailInput = ref(null);
const tokenInput = ref(null);
const route = useRoute();
const router = useRouter();
const isToken = computed(() => token.value.length < 64);

const api = computed(() => {
  let baseApi = process.env.VUE_APP_API;
  const currentHost = window.location.host.replace(/:\d+$/, "");
  if (baseApi.match(/localhost/) && currentHost.match(/^\d+/)) {
    log(`Dev server running on local network IP`);
    baseApi = baseApi.replace(/localhost/, currentHost);
  }
  return baseApi;
});

function intersection(arr1, arr2) {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) return [];
  return arr1.filter((item) => arr2.includes(item));
}

function isSignedIn() {
  message.value = "";
  token.value = "";
  let userEvents = JSON.parse(localStorage.getItem("events") || "{}");
  log(`userEvents = ${JSON.stringify(userEvents)}`);
  const toArray = Array.isArray(props.to) ? props.to : [props.to];
  log(`toArray = ${JSON.stringify(toArray)}`);
  const signedInEvents = intersection(Object.keys(userEvents), toArray) || [];
  log(`signedInEvents = ${JSON.stringify(signedInEvents)}`);
  if (signedInEvents.length > 0) {
    emit("signedIn", signedInEvents);
  } else {
    log(`not yet signed in to ${props.to}; ask for email`);
  }
  step.value = 1;
  return signedInEvents.length > 0;
}

const routeLabel = computed(() => {
  if (route.name === "Video") return "video";
  if (route.name === "Live") return "event";
  return "page";
});

async function verify_email(to) {
  log(`verify_email(${to}) called.`);
  const response = await verify_email_call(to);
  if (!response) {
    message.value = `VIZBI sign-in is offline; please try later`;
    return;
  }
  const responseJson = await response.json();
  if (responseJson.error) {
    log(`route.name = ${route.name}`);
    message.value = responseJson.error.match(/not registered/)
      ? `Unknown email. Did you register using another email?`
      : `No registration found for this address.`;
  } else {
    localStorage.email = address.value;
    message.value = `Confirmation email has been sent.`;
    step.value = 3;
  }
  clickCount.value++;
}

function email_is_valid(email) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

async function fetchWithTimeout(resource, options = {}) {
  const {timeout = 8000} = options;
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, {...options, signal: controller.signal});
  clearTimeout(id);
  return response;
}

async function verify_email_call(to) {
  const showcases = typeof to === "string" ? [to] : to;
  try {
    return await fetchWithTimeout(`${api.value}/user/verify_email`, {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: stringify({
        email: address.value,
        path: route.path,
        showcases
      })
    });
  } catch (err) {
    log(`Problem calling verify_email: ${err}`);
  }
}

async function authenticate(tokenStr) {
  if (!tokenStr) return;
  try {
    const response = await authenticate_call(tokenStr);
    const resJson = await response?.json();
    if (!resJson) {
      message.value = "No response from server - please try again later.";
    } else if (resJson.status === "success") {
      localStorage.loginToken = resJson.loginToken;
      localStorage.events = JSON.stringify(resJson.events);
      step.value = "";
      isSignedIn(); // determine if user is signedIn for the 'to' events
      // emit("signedIn");
    } else {
      tokenError.value = resJson.error === "expired token" ? "has expired" : "is invalid";
      step.value = 4;
    }
  } catch (error) {
    console.error("Error in authenticate():", error);
    message.value = "An unexpected error occurred. Please try again later.";
    step.value = 4;
  }
}

async function authenticate_call(tokenStr) {
  try {
    return await fetchWithTimeout(`${api.value}/user/authenticate`, {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: stringify({token: tokenStr})
    });
  } catch (err) {
    log(`Problem verifying authentication code: ${err}`);
    step.value = 4;
  }
}

async function read_token_from_URL() {
  const urlToken = window.location.hash.replace(/^#/, "");
  if (!urlToken) return log(`URL fragment is empty.`);

  try {
    await authenticate(urlToken);
    history.pushState({}, "", route.path);
  } catch (err) {
    log(`Error reading token from URL: ${err}`);
  }
  step.value = 3;
}

function formatStringArray(arr) {
  if (arr.length === 0) return "";
  if (arr.length === 1) return arr[0];
  if (arr.length === 2) return `${arr[0]} or ${arr[1]}`;
  return `${arr.slice(0, -1).join(", ")} or ${arr[arr.length - 1]}`;
}

onMounted(async () => {
  log(`mounted with to = ${props.to}`);
  log(`URL path = ${route.path}`);
  if (window.location.hash && isToken.value) {
    log("process token in URL");
    await read_token_from_URL();
  } else if (isSignedIn()) {
    return true;
  }
});

watch(token, () => {
  if (token.value && tokenInput.value) {
    tokenInput.value.style.width = tokenInput.value.value.length + "ch";
  }
});

watch(address, () => {
  if (emailInput.value) {
    emailInput.value.style.width = emailInput.value.value.length + "ch";
  }
  message.value = "";
});
</script>

<style scoped>
/* https://stackoverflow.com/q/59632929 */
.v-enter-from {
  opacity: 0;
  opacity: 0;
}
.v-enter-to {
  opacity: 1;
  /* transform: scale(1); */
}
.v-enter-active {
  transition: all 0.5s ease;
}
/* .v-move {transition: all 5s ease;} */
.v-leave-from {
  opacity: 1;
}
.v-leave-to {
  opacity: 0;
}
.v-leave-active {
  transition: all 0.5s ease;
}
section {
  display: flex;
  flex-direction: column;
  /* sets main axis as vertical */
  gap: 20px;
  /* vertical spacing between children */
  align-items: center;
  /* aligns children + text within children */
  background-color: #f8f8f8;
  border: 1px solid #bdbdbd;
  padding: 25px;
  /* separates border from children */
  margin-top: 25px;
  margin-bottom: 52px;
}
section > * {
  /* selects all direct children of each section */
  /* https://stackoverflow.com/q/43739470 */
  flex: 1;
  /* give each child approximately equal height */
  margin: 0px;
  padding: 0px;
  text-align: center;
}
section h5 {
  font-size: 1.05rem;
}
section p {
  font-style: italic;
}
section input {
  min-width: 16rem;
  max-width: 95%;
  padding: 0.2em;
}
section div {
  display: inline-flex;
  flex-direction: row;
  /* sets main axis as vertical */
  gap: 12px;
  /* background-color: blue; */
  /* for testing */
}
section div button {
  flex: 2;
  /* background-color: red; */
  /* for testing */
}
button,
input {
  margin: 0px;
  /* 20px */
  padding: 8px 16px;
  text-align: center;
}
.backButton {
  left: 0%;
  top: 0%;
  transform: translate(-20%, -20%);
  background: linear-gradient(to bottom, #bdbdbd 5%, #d5d5d5 100%);
  border: 1px solid #bdbdbd;
  box-shadow: inset 0px 1px 0px 0px #bdbdbd;
  text-shadow: 0px 1px 0px #bdbdbd;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  color: #ffffff;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
}
.message,
.debugMessage {
  color: red;
}
</style>

<!-- https://github.com/zoom/meetingsdk-vuejs-sample/blob/master/src/components/HelloWorld.vue -->

<template>
  <div id="container">
    <div id="zmmtg-root" />
    <!-- <h1>Zoom Meeting SDK Vue.js Sample</h1> -->
    <button @click="getSignature">Join Meeting</button>
  </div>
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(`Zoom: ${msg}`); // eslint-disable-line
const props = defineProps(["id"]);
import {computed, onMounted} from "vue";
import {ZoomMtg} from "@zoom/meetingsdk";
const authEndpoint = "";
const sdkKey = "";
const role = 0;
const userName = "Vue.js";
const userEmail = "";
const registrantToken = "";
const zakToken = "";
const leaveUrl = "http://localhost:5173/";
const details = {
  75818735001: {pwd: "TFhH4WeKAxULPtnsbwmF3LoJPb0Edc.1", comment: "Sean's test meeting"},
  97109131082: {
    pwd: "AWh72dXraqET5PtC15YO2HaPbE62jc.1",
    Passcode: "205349"
  },
  96051363221: {
    pwd: "iVBpuQwVv4O0htZjaJmd4N3WFkcAGF.1",
    Passcode: "661495"
  },
  96288440856: {
    pwd: "TSh04HXOn050a8wau41otJuoGz3n5O.1",
    Passcode: "046589"
  },
  95547228784: {
    pwd: "cq9lx8rFQWIbdYPpVcKdZKajD0Bf84.1",
    Passcode: "522092"
  }
};
const pwd = computed(() => details[props.id].pwd);

function getSignature() {
  fetch(authEndpoint, {
    method: "POST",
    headers: {"Content-Type": "application/json"},
    body: JSON.stringify({meetingNumber: props.id, role})
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      startMeeting(data.signature);
    })
    .catch((error) => console.log(error));
}

function startMeeting(signature) {
  document.getElementById("zmmtg-root").style.display = "block";
  ZoomMtg.init({
    leaveUrl,
    patchJsMedia: true,
    leaveOnPageUnload: true,
    success: (success) => {
      console.log(success);
      ZoomMtg.join({
        signature,
        sdkKey,
        meetingNumber: props.id,
        passWord: pwd.value,
        userName,
        userEmail,
        tk: registrantToken,
        zak: zakToken,
        success: (success) => console.log(success),
        error: (error) => console.log(error)
      });
    },
    error: (error) => console.log(error)
  });
}

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();

onMounted(() => log(`[props.id, pwd.value] = [${props.id}, ${pwd.value}]`));
</script>

<style scoped>
#container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  align-self: stretch; /* fill horizontal space */
  min-height: 100%;
  margin: auto;
  background-color: black;
  /* max-width: 800px;
  max-height: 600px; */
  margin: auto;
  overflow: hidden;
}
.narrow #container {
  background-color: white;
}
#zmmtg-root {
  /* width: 80%;
  height: 80%;
  max-width: 800px;
  max-height: 600px; */
  /* margin: auto; */
  overflow: hidden;
  background-color: blue;
}
button {
  font-size: 1.5rem;
  color: white;
  background-color: var(--brand-color);
  margin: 0 auto;
  width: 80%;
}
.narrow button {
  width: 100%;
}
</style>

<!-- Let's user decide if they want to open current Zoom meeting, or return to /Live -->

<template>
  <ModalDialog background="#565656" :hash="hash">
    <template #default>
      <div class="thanks">
        <h2 ref="h1Ref">Join the Meeting</h2>
        <p>VIZBI is now live. Click the button to join.</p>
        <p>
          <a :href="url"><ButtonBespoke :outstanding="true">Join</ButtonBespoke></a>
        </p>
      </div>
    </template>
  </ModalDialog>
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(`JoinMeeting: ${msg}`); // eslint-disable-line
const props = defineProps(["url"]);
import {onMounted, ref} from "vue";
import ModalDialog from "@/components/ModalDialog/Index";
import ButtonBespoke from "@/components/ButtonBespoke";
import {useMaxFontSize} from "@/composables/useMaxFontSize";
const hash = "join";

const h1Ref = ref(null);
useMaxFontSize(h1Ref);

onMounted(() => (location.hash = hash));
</script>

<style scoped>
div > * {
  color: white;
  background-color: #565656;
  padding: 20px 20px 0 20px;
}
h2 {
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
}
button {
  /* font-size: var(--medium); */
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1;
}
</style>

// Determines the program is complete enough to show /Live and /Public pages

const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line
import {computed} from "vue";
import history from "@/assets/history.json";

export default function showEvents() {
  const showEvents = computed(() => {
    const {preRegistrationOpen, registrationOpen} = history;
    if (preRegistrationOpen || registrationOpen) return true;
    return false;
  });
  return showEvents;
}

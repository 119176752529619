<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <div v-if="false">
    <p>finished = {{ finished }}</p>
    <p>showWhenFinished = {{ showWhenFinished }}</p>
  </div>
  <div v-if="!finished" id="counter">
    <ul v-for="(count, index) in display" :key="index" :class="{highlight: highlight}">
      <li class="count">{{ count }}</li>
      <li class="label">{{ labels[index] }}</li>
    </ul>
  </div>
  <div v-else-if="finished && showWhenFinished">Boom</div>
</template>

<script setup>
import {ref, computed, onMounted, onBeforeUnmount} from "vue";
import {DateTime, Duration} from "luxon";

const props = defineProps({
  showWhenFinished: {type: Boolean, default: false},
  until: {type: Object, default: () => ({})},
  highlight: {default: false}
});

const emit = defineEmits(["hideCounter"]);

const now = ref(DateTime.local().set({milliseconds: 0}));
const labels = ["days", "hours", "mins", "secs"];
const tick = ref(null);

const remaining = computed(() => props.until.diff(now.value).toObject());

const remainingFormated = computed(() =>
  Duration.fromObject(remaining.value).toFormat("dd hh:mm:ss")
);

const display = computed(() => {
  const match = remainingFormated.value.match(/^(\d+) (\d+):(\d+):(\d+)/);
  if (!match) return [0, 0, 0, 0];
  match.shift();
  return match;
});

const finished = computed(() => {
  if (now.value < props.until) return false;
  log("finished");
  emit("hideCounter", props.until);
  return true;
});

function log(message) {
  if (process.env.VUE_APP_DEV) {
    console.log(message);
  }
}

function startTimer() {
  return setInterval(() => {
    now.value = DateTime.local().set({milliseconds: 0});
  }, 100);
}

onMounted(() => {
  log("Countdown mounted");
  tick.value = startTimer();

  document.addEventListener("visibilitychange", handleVisibility);
});

onBeforeUnmount(() => {
  clearInterval(tick.value);
  document.removeEventListener("visibilitychange", handleVisibility);
});

function handleVisibility() {
  if (document.hidden) {
    log("tab now inactive - temporarily clear timer");
    clearInterval(tick.value);
  } else {
    log("tab is active again, so restarting timer");
    tick.value = startTimer();
  }
}
</script>

<style scoped>
#counter {
  display: flex;
  flex-direction: row;
  gap: 20px; /* horizontal spacing between children */
  align-items: center; /* aligns children + text within children */
  text-align: center;
  margin: 0px;
  padding: 0px;
}

ul {
  flex-grow: 1;
  flex-basis: 0;
  margin: 0px;
  padding: 0px;
  min-width: 0; /* prevents from overflowing */
}

li {
  list-style-type: none;
  color: black;
  margin: 0px;
  padding: 0px;
  min-width: 0; /* prevents from overflowing */
}

.highlight li {
  color: red;
}

.count {
  font-size: 2rem;
}
</style>

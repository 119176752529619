<!-- https://github.com/zoom/meetingsdk-vuejs-sample/blob/master/src/components/HelloWorld.vue -->

<template>
  <div id="container">
    <button @click="joinMeeting">Join Meeting</button>
    <JoinMeeting v-if="url" :url="url" />
  </div>
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(`Zoom: ${msg}`); // eslint-disable-line
const props = defineProps(["id"]);
import {computed} from "vue";
import JoinMeeting from "@/components/ModalDialog/JoinMeeting";
const path = `https://us04web.zoom.us/j`;
const details = {
  94303956456: {pwd: "UvbBPcFeCzhFxathYSvrQUzNSiTbQv.1", comment: "Test meeting"},
  97109131082: {
    pwd: "AWh72dXraqET5PtC15YO2HaPbE62jc.1",
    Passcode: "205349"
  },
  96051363221: {
    pwd: "iVBpuQwVv4O0htZjaJmd4N3WFkcAGF.1",
    Passcode: "661495"
  },
  96288440856: {
    pwd: "TSh04HXOn050a8wau41otJuoGz3n5O.1",
    Passcode: "046589"
  },
  95547228784: {
    pwd: "cq9lx8rFQWIbdYPpVcKdZKajD0Bf84.1",
    Passcode: "522092"
  }
};
const url = computed(() => {
  if (!props.id) return console.error("Missing meeting ID.");
  if (!details[props.id]) return console.error("Missing meeting details.");
  if (!details[props.id].pwd) return console.error("Missing password.");
  const pwd = encodeURIComponent(details[props.id].pwd);
  return `${path}/${props.id}?pwd=${pwd}`;
});

function joinMeeting() {
  log(`Redirecting to Zoom URL: ${url.value}`);
  window.open(url.value, "_blank");
}
</script>

<style scoped>
#container {
  display: flex;
  justify-content: center; /* horizontal centering */
  align-items: center; /* vertical centering */
  width: 100%;
  height: 100%;
  align-self: stretch; /* fill horizontal space */
  min-height: 100%;
  margin: auto;
  background-color: black;
  margin: auto;
  overflow: hidden;
}
:is(.narrow #container, .medium #container) {
  background-color: #f1f1f1;
}
button {
  font-size: 1.5rem;
  color: white;
  background-color: var(--brand-color);
  margin: 0 auto;
  width: 80%;
}
.narrow button {
  width: 100%;
}
</style>
